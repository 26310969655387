<template>
  <ProcessCard
    :title="$t('sections.sell-us-process.noOfferSection.title')"
    :subtitle="$t('sections.sell-us-process.noOfferSection.subtitle')"
    class="align-items-center"
  >
    <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.noOfferSection.inputs.name.value'
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.noOfferSection.inputs.name.placeholder'
            )
          "
          :value="name"
          @input="(e) => handleInput('name', e)"
        />
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.noOfferSection.inputs.email.value'
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.noOfferSection.inputs.email.placeholder'
            )
          "
          :value="email"
          @input="(e) => handleInput('email', e)"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'SellUsProcessNoOfferSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
  },
  data() {
    return {
      name: '',
      email: '',
    };
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 24px;
  width: 70%;
}
</style>
