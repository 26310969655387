<template>
  <ProcessCard :title="$t('sections.sell-us-process.valueSection.title')">
    <template slot="body">
      <div class="inputs">
        <LargeInput
          :title="
            $t('sections.sell-us-process.valueSection.inputs.value.value')
          "
          :placeholder="
            $t('sections.sell-us-process.valueSection.inputs.value.placeholder')
          "
          :value="value"
          :valid="this.validFields.value"
          :error-label="$t('components.input.invalidNumber')"
          @input="(e) => handleInput('value', e)"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, LargeInput } from 'ff-components-lib';

export default {
  name: 'SellUsProcessValueSection',
  components: {
    ProcessCard,
    LargeInput,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      value: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
