<template>
  <ProcessCard
    :title="$t('sections.sell-us-process.endSection.title')"
    success
  >
    <!-- TODO <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.endSection.inputs.address.value',
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.endSection.inputs.address.placeholder',
            )
          "
          :value="address"
          @input="handleInput"
        />
      </div>
    </template> -->
  </ProcessCard>
</template>
<script>
import { ProcessCard } from 'ff-components-lib';
// import { ProcessCard, VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'SellUsProcessEndSection',
  components: {
    ProcessCard,
    // VerticalTitleInput,
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
