<template>
  <ProcessCard
    :title="$t('sections.sell-us-process.propertyInfoSection.title')"
    :subtitle="$t('sections.sell-us-process.propertyInfoSection.subtitle')"
  >
    <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.propertyInfoSection.inputs.address.value',
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.propertyInfoSection.inputs.address.placeholder',
            )
          "
          :value="address"
          :valid="this.validFields.address"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('address', e)"
        />
        <div class="row">
          <div class="col-md-6 col-12 mb-md-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.city.value',
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.city.placeholder',
                )
              "
              :value="city"
              :valid="this.validFields.city"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('city', e)"
            />
          </div>
          <div class="col-sm-5 col-md-3 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.postalCode.value',
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.postalCode.placeholder',
                )
              "
              :value="postalCode"
              :valid="this.validFields.postalCode"
              :error-label="$t('components.input.invalidNumber')"
              @input="(e) => handleInput('postalCode', e)"
            />
          </div>
          <div class="col-sm-5 col-md-3 col-12">
            <VerticalTitleInput
              type="dropdown"
              :title="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.district.value',
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.district.placeholder',
                )
              "
              :value="district"
              :items="$t('arrays.districts')"
              :valid="this.validFields.district"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('district', e)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-6 mb-sm-0 mb-6">
            <VerticalTitleInput
              name="typology"
              :title="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.typology.value',
                )
              "
              :placeholder="$t('components.dropdown.defaultPlaceholder')"
              :value="typology"
              type="dropdown"
              :items="$t('arrays.typology')"
              :valid="this.validFields.typology"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('typology', e)"
            />
          </div>
          <div class="col-sm-3 col-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.area.value',
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.area.placeholder',
                )
              "
              :unit="
                $t(
                  'sections.sell-us-process.propertyInfoSection.inputs.area.unit',
                )
              "
              :value="area"
              :valid="this.validFields.area"
              :error-label="$t('components.input.invalidNumber')"
              @input="(e) => handleInput('area', e)"
            />
          </div>
        </div>
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, VerticalTitleInput } from 'ff-components-lib';

export default {
  name: 'SellUsProcessPropertyInfoSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
  },
  props: {
    fields: {
      type: Object,
      default: null,
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      address: undefined,
      city: undefined,
      postalCode: undefined,
      district: undefined,
      typology: undefined,
      area: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
