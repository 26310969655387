var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.sell-us-process.contactInfoSection.title')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.contactInfoSection.inputs.name.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.contactInfoSection.inputs.name.placeholder'
          ),"value":_vm.name,"valid":this.validFields.name,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('name', e); }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-7 col-12 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                'sections.sell-us-process.contactInfoSection.inputs.email.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.contactInfoSection.inputs.email.placeholder'
              ),"value":_vm.email,"valid":this.validFields.email,"error-label":_vm.$t('components.input.invalidEmail')},on:{"input":function (e) { return _vm.handleInput('email', e); }}})],1),_c('div',{staticClass:"col-sm-5 col-12 mb-sm-0 mb-6"},[_c('PhoneInput',{attrs:{"name":"phone-number","title":_vm.$t(
                'sections.sell-us-process.contactInfoSection.inputs.phone.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.contactInfoSection.inputs.phone.placeholder'
              ),"code-placeholder":_vm.$t(
                'sections.sell-us-process.contactInfoSection.inputs.countryCode.placeholder'
              ),"valid":this.validFields.phone,"valid-code":this.validFields.countryCode,"error-label":_vm.$t('components.input.invalidPhone'),"value":_vm.phone,"code-value":_vm.countryCode,"items":_vm.$t('arrays.phoneCountryCodes')},on:{"input":function (e) { return _vm.handleInput('phone', e); },"codeInput":function (e) { return _vm.handleInput('countryCode', e); }}})],1)])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }