<template>
  <ProcessCard :title="$t('sections.sell-us-process.deadlineSection.title')">
    <template slot="body">
      <div class="inputs">
        <ChoiceCardGroup
          name="deadline"
          :choices="$t('sections.sell-us-process.deadlineSection.inputs.cards')"
          :value="sellDeadline"
          :max-items-row="getBreakpoints.smAndUp ? 2 : 1"
          @onChoice="(e) => handleInput('sellDeadline', e)"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { mapGetters } from 'vuex';
import { ProcessCard, ChoiceCardGroup } from 'ff-components-lib';

export default {
  name: 'SellUsProcessDeadlineSection',
  components: {
    ProcessCard,
    ChoiceCardGroup,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sellDeadline: undefined,
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  gap: 24px;
  height: 100%;
}
</style>
