var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.sell-us-process.propertyInfoSection.title'),"subtitle":_vm.$t('sections.sell-us-process.propertyInfoSection.subtitle')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.propertyInfoSection.inputs.address.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.propertyInfoSection.inputs.address.placeholder'
          ),"value":_vm.address,"valid":this.validFields.address,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('address', e); }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12 mb-md-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.city.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.city.placeholder'
              ),"value":_vm.city,"valid":this.validFields.city,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('city', e); }}})],1),_c('div',{staticClass:"col-sm-5 col-md-3 col-12 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.postalCode.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.postalCode.placeholder'
              ),"value":_vm.postalCode,"valid":this.validFields.postalCode,"error-label":_vm.$t('components.input.invalidNumber')},on:{"input":function (e) { return _vm.handleInput('postalCode', e); }}})],1),_c('div',{staticClass:"col-sm-5 col-md-3 col-12"},[_c('VerticalTitleInput',{attrs:{"type":"dropdown","title":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.district.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.district.placeholder'
              ),"value":_vm.district,"items":_vm.$t('arrays.districts'),"valid":this.validFields.district,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('district', e); }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4 col-6 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"name":"typology","title":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.typology.value'
              ),"placeholder":_vm.$t('components.dropdown.defaultPlaceholder'),"value":_vm.typology,"type":"dropdown","items":_vm.$t('arrays.typology'),"valid":this.validFields.typology,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('typology', e); }}})],1),_c('div',{staticClass:"col-sm-3 col-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.area.value'
              ),"placeholder":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.area.placeholder'
              ),"unit":_vm.$t(
                'sections.sell-us-process.propertyInfoSection.inputs.area.unit'
              ),"value":_vm.area,"valid":this.validFields.area,"error-label":_vm.$t('components.input.invalidNumber')},on:{"input":function (e) { return _vm.handleInput('area', e); }}})],1)])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }