<template>
  <ProcessLayout
    :steps="7"
    :finish="processStep === 7"
    :show-skip="processStep === 6"
    :next-disabled="!validStep"
    @back="previousStep"
    @next="nextStep"
    @skip="nextStep"
    @finish="handleFinish"
  >
    <template slot="body">
      <SellUsProcessPropertyInfoSection
        v-if="processStep === 0"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessContactInfoSection
        v-else-if="processStep === 1"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessRepresentationSection
        v-else-if="processStep === 2"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessListedPropertySection
        v-else-if="processStep === 3"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessDeadlineSection
        v-else-if="processStep === 4"
        :fields="fields"
        @input="handleInput"
      />
      <SellUsProcessValueSection
        v-else-if="processStep === 5"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessPhotosSection
        v-else-if="processStep === 6"
        :fields="fields"
        :valid-fields="validFields"
        @input="handleInput"
      />
      <SellUsProcessEndSection v-else-if="processStep === 7" />
      <SellUsProcessNoOfferSection v-else-if="processStep === 8" />
      <SellUsProcessNoOfferEndSection v-else-if="processStep === 9" />
    </template>
  </ProcessLayout>
</template>
<script>
import {
  validateZipCode,
  validateEmail,
  validatePhone,
  validateNumber,
} from '@/utils';
import ProcessLayout from '@/components/layout/process/ProcessLayout';
import SellUsProcessPropertyInfoSection from '@/components/sections/processes/sell-us-process/SellUsProcessPropertyInfoSection';
import SellUsProcessContactInfoSection from '@/components/sections/processes/sell-us-process/SellUsProcessContactInfoSection';
import SellUsProcessRepresentationSection from '@/components/sections/processes/sell-us-process/SellUsProcessRepresentationSection';
import SellUsProcessListedPropertySection from '@/components/sections/processes/sell-us-process/SellUsProcessListedPropertySection';
import SellUsProcessDeadlineSection from '@/components/sections/processes/sell-us-process/SellUsProcessDeadlineSection';
import SellUsProcessValueSection from '@/components/sections/processes/sell-us-process/SellUsProcessValueSection';
import SellUsProcessPhotosSection from '@/components/sections/processes/sell-us-process/SellUsProcessPhotosSection';
import SellUsProcessEndSection from '@/components/sections/processes/sell-us-process/SellUsProcessEndSection';
import SellUsProcessNoOfferSection from '@/components/sections/processes/sell-us-process/SellUsProcessNoOfferSection';
import SellUsProcessNoOfferEndSection from '@/components/sections/processes/sell-us-process/SellUsProcessNoOfferEndSection';

export default {
  name: 'SellUsProcessScreen',
  components: {
    ProcessLayout,
    SellUsProcessPropertyInfoSection,
    SellUsProcessContactInfoSection,
    SellUsProcessRepresentationSection,
    SellUsProcessListedPropertySection,
    SellUsProcessDeadlineSection,
    SellUsProcessValueSection,
    SellUsProcessPhotosSection,
    SellUsProcessEndSection,
    SellUsProcessNoOfferSection,
    SellUsProcessNoOfferEndSection,
  },
  data() {
    return {
      processStep: 0,
      fields: {
        address: undefined,
        city: undefined,
        postalCode: undefined,
        district: undefined,
        typology: undefined,
        area: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
        representationType: undefined,
        agencyName: undefined,
        activeListing: undefined,
        listingLink: undefined,
        sellDeadline: undefined,
        value: undefined,
        files: [],
      },
    };
  },
  computed: {
    validAddress() {
      return (
        this.fields.address === undefined
        || (typeof this.fields.address === 'string'
          && this.fields.address.length > 0)
      );
    },
    validCity() {
      return (
        this.fields.city === undefined
        || (typeof this.fields.city === 'string' && this.fields.city.length > 0)
      );
    },
    validPostalCode() {
      if (this.fields.postalCode === undefined) {
        return true;
      }
      if (this.fields.postalCode && this.fields.postalCode.length > 0) {
        return validateZipCode(this.fields.postalCode);
      }
      return false;
    },
    validDistrict() {
      return (
        this.fields.district === undefined
        || (typeof this.fields.district === 'string'
          && this.fields.district.length > 0)
      );
    },
    validTypology() {
      return (
        this.fields.typology === undefined
        || (typeof this.fields.typology === 'string'
          && this.fields.typology.length > 0)
      );
    },
    validArea() {
      if (this.fields.area === undefined) {
        return true;
      }
      if (this.fields.area && this.fields.area.length > 0) {
        return validateNumber(this.fields.area);
      }
      return false;
    },
    validName() {
      return (
        this.fields.name === undefined
        || (typeof this.fields.name === 'string' && this.fields.name.length > 0)
      );
    },
    validEmail() {
      if (this.fields.email === undefined) {
        return true;
      }
      if (this.fields.email && this.fields.email.length > 0) {
        return validateEmail(this.fields.email);
      }
      return false;
    },
    validPhoneCountryCode() {
      return (
        this.fields.countryCode === undefined
        || (typeof this.fields.countryCode === 'string'
          && this.fields.countryCode.length > 0)
      );
    },
    validPhone() {
      if (this.fields.phone === undefined) {
        return true;
      }
      if (this.fields.phone && this.fields.phone.length > 0) {
        return validatePhone(this.fields.phone);
      }
      return false;
    },
    validAgencyName() {
      if (this.fields.representationType === 'Agência') {
        return (
          this.fields.agencyName === undefined
          || (typeof this.fields.agencyName === 'string'
            && this.fields.agencyName.length > 0)
        );
      }
      return true;
    },
    validListingLink() {
      if (this.fields.activeListing === 'Sim') {
        return (
          this.fields.listingLink === undefined
          || (typeof this.fields.listingLink === 'string'
            && this.fields.listingLink.length > 0)
        );
      }
      return true;
    },
    validValue() {
      if (this.fields.value === undefined) {
        return true;
      }
      if (this.fields.value && this.fields.value.length > 0) {
        return validateNumber(this.fields.value);
      }
      return false;
    },
    validFields() {
      return {
        address: this.validAddress,
        city: this.validCity,
        postalCode: this.validPostalCode,
        district: this.validDistrict,
        typology: this.validTypology,
        area: this.validArea,
        name: this.validName,
        email: this.validEmail,
        countryCode: this.validPhoneCountryCode,
        phone: this.validPhone,
        agencyName: this.validAgencyName,
        listingLink: this.validListingLink,
        value: this.validValue,
      };
    },
    validStep() {
      if (this.processStep === 0) {
        return (
          this.fields.address != null
          && this.validAddress
          && this.fields.city != null
          && this.validCity
          && this.fields.postalCode != null
          && this.validPostalCode
          && this.fields.district != null
          && this.validDistrict
          && this.fields.typology != null
          && this.validTypology
          && this.fields.area != null
          && this.validArea
        );
      }
      if (this.processStep === 1) {
        return (
          this.fields.name != null
          && this.validName
          && this.fields.email != null
          && this.validEmail
          && this.fields.countryCode != null
          && this.validPhoneCountryCode
          && this.fields.phone != null
          && this.validPhone
        );
      }
      if (this.processStep === 2) {
        let validAgencyName = true;
        if (this.fields.representationType === 'Agência') {
          validAgencyName = this.fields.agencyName != null && this.validAgencyName;
        }
        return this.fields.representationType != null && validAgencyName;
      }
      if (this.processStep === 3) {
        let validListingLink = true;
        if (this.fields.activeListing === 'Sim') {
          validListingLink = this.fields.listingLink != null && this.validListingLink;
        }
        return this.fields.activeListing != null && validListingLink;
      }
      if (this.processStep === 4) {
        return this.fields.sellDeadline != null;
      }
      if (this.processStep === 5) {
        return this.fields.value != null && this.validValue;
      }
      if (this.processStep === 6) {
        return this.fields.files != null && this.fields.files.length > 0;
      }
      return true;
    },
  },
  mounted() {
    if (this.$route.params.address) {
      this.fields.address = this.$route.params.address;
    }
  },
  methods: {
    previousStep() {
      if (this.processStep === 0) {
        this.$router.back();
      }
      if (this.processStep > 0) {
        this.processStep -= 1;
        window.scrollTo(0, 0);
      }
    },
    nextStep() {
      if (this.processStep === 6) {
        this.sendProposal();
      } else if (this.processStep < 7) {
        this.processStep += 1;
        window.scrollTo(0, 0);
      }
    },
    handleInput({ field, value }) {
      this.fields[field] = value;
    },
    async sendProposal() {
      try {
        const proposal = {
          address: this.fields.address,
          city: this.fields.city,
          postalCode: this.fields.postalCode,
          district: this.fields.district,
          typology: this.fields.typology,
          area: this.fields.area,
          name: this.fields.name,
          email: this.fields.email,
          countryCode: this.fields.countryCode,
          phone: this.fields.phone,
          representationType: this.fields.representationType,
          activeListing: this.fields.activeListing,
          sellDeadline: this.fields.sellDeadline,
          value: this.fields.value,
        };

        if (this.fields.representationType === 'Agência') {
          proposal.agencyName = this.fields.agencyName;
        }
        if (this.fields.activeListing === 'Sim') {
          proposal.listingLink = this.fields.listingLink;
        }

        await this.$store.dispatch('sendSellUsEmail', { proposal, files: this.fields.files });

        this.processStep += 1;
        window.scrollTo(0, 0);
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    handleFinish() {
      this.$router.push({ name: 'sell' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
