<template>
  <ProcessCard
    :title="$t('sections.sell-us-process.contactInfoSection.title')"
  >
    <template slot="body">
      <div class="inputs">
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.contactInfoSection.inputs.name.value'
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.contactInfoSection.inputs.name.placeholder'
            )
          "
          :value="name"
          :valid="this.validFields.name"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('name', e)"
        />
        <div class="row">
          <div class="col-sm-7 col-12 mb-sm-0 mb-6">
            <VerticalTitleInput
              :title="
                $t(
                  'sections.sell-us-process.contactInfoSection.inputs.email.value'
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.contactInfoSection.inputs.email.placeholder'
                )
              "
              :value="email"
              :valid="this.validFields.email"
              :error-label="$t('components.input.invalidEmail')"
              @input="(e) => handleInput('email', e)"
            />
          </div>
          <div class="col-sm-5 col-12 mb-sm-0 mb-6">
            <PhoneInput
              name="phone-number"
              :title="
                $t(
                  'sections.sell-us-process.contactInfoSection.inputs.phone.value'
                )
              "
              :placeholder="
                $t(
                  'sections.sell-us-process.contactInfoSection.inputs.phone.placeholder'
                )
              "
              :code-placeholder="
                $t(
                  'sections.sell-us-process.contactInfoSection.inputs.countryCode.placeholder'
                )
              "
              :valid="this.validFields.phone"
              :valid-code="this.validFields.countryCode"
              :error-label="$t('components.input.invalidPhone')"
              :value="phone"
              :code-value="countryCode"
              :items="$t('arrays.phoneCountryCodes')"
              @input="(e) => handleInput('phone', e)"
              @codeInput="(e) => handleInput('countryCode', e)"
            />
          </div>
        </div>
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, VerticalTitleInput, PhoneInput } from 'ff-components-lib';

export default {
  name: 'SellUsProcessContactInfoSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
    PhoneInput,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: undefined,
      email: undefined,
      countryCode: undefined,
      phone: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
