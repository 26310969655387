<template>
  <ProcessCard
    :title="$t('sections.sell-us-process.representationInfoSection.title')"
  >
    <template slot="body">
      <div class="inputs">
        <ChoiceCardGroup
          name="representation"
          :choices="
            $t(
              'sections.sell-us-process.representationInfoSection.inputs.cards',
            )
          "
          :value="representationType"
          @onChoice="(e) => handleInput('representationType', e)"
        />
        <VerticalTitleInput
          :title="
            $t(
              'sections.sell-us-process.representationInfoSection.inputs.agencyName.value',
            )
          "
          :placeholder="
            $t(
              'sections.sell-us-process.representationInfoSection.inputs.agencyName.placeholder',
            )
          "
          :value="agencyName"
          :valid="this.validFields.agencyName"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('agencyName', e)"
          v-if="
            representationType ===
              $t(
                'sections.sell-us-process.representationInfoSection.inputs.cards',
              )[1]
          "
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import {
  ProcessCard,
  VerticalTitleInput,
  ChoiceCardGroup,
} from 'ff-components-lib';

export default {
  name: 'SellUsProcessRepresentationSection',
  components: {
    ProcessCard,
    VerticalTitleInput,
    ChoiceCardGroup,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    validFields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      representationType: undefined,
      agencyName: undefined,
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  gap: 24px;
  width: 84%;
  height: 100%;
}
</style>
