<template>
  <ProcessCard :title="$t('sections.sell-us-process.photosSection.title')">
    <template slot="body">
      <div class="inputs">
        <span class="label-text">
          {{ $t('sections.sell-us-process.photosSection.loadImages') }}
        </span>
        <FileUpload
          :text="
            isDragging
              ? $t('components.fileUpload.dropText')
              : $t('components.fileUpload.text')
          "
          :upload-text="
            isDragging ? '' : $t('components.fileUpload.uploadText')
          "
          :formats="isDragging ? '' : $t('components.fileUpload.formats')"
          @dragover="isDragging = true"
          @dragleave="isDragging = false"
          @upload="handleUpload"
        />
        <span
          class="label-text"
          v-if="files.length > 0"
        >
          {{ $t('sections.sell-us-process.photosSection.files') }}
        </span>
        <FileList
          name="listing-photos"
          :files="files"
          @delete="handleFileDelete"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { ProcessCard, FileList, FileUpload } from 'ff-components-lib';

export default {
  name: 'SellUsProcessPhotosSection',
  components: {
    ProcessCard,
    FileList,
    FileUpload,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    files() {
      this.$emit('input', { field: 'files', value: this.files });
    },
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    async handleUpload(files) {
      if (files.length === 0) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.invalidFileFormat'),
          type: 'error',
        });
      } else {
        this.files.push(...files);
      }
    },
    handleFileDelete(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 24px;
}

.label-text {
  font-weight: 500;
  line-height: 24px;
}

@media only screen and (max-width: 770px) {
  .inputs {
    gap: 14px;
  }

  .label-text {
    font-size: 14px;
  }
}
</style>
