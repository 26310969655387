var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.sell-us-process.listedPropertySection.title')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('ChoiceCardGroup',{attrs:{"name":"for-sale","choices":_vm.$t('sections.sell-us-process.listedPropertySection.inputs.cards'),"value":_vm.activeListing,"max-items-row":_vm.getBreakpoints.smAndUp ? 2 : 1},on:{"onChoice":function (e) { return _vm.handleInput('activeListing', e); }}}),(
          _vm.activeListing ===
            _vm.$t(
              'sections.sell-us-process.listedPropertySection.inputs.cards'
            )[0]
        )?_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.listedPropertySection.inputs.link.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.listedPropertySection.inputs.link.placeholder'
          ),"value":_vm.listingLink,"valid":this.validFields.listingLink,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('listingLink', e); }}}):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }