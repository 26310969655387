var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{staticClass:"align-items-center",attrs:{"title":_vm.$t('sections.sell-us-process.noOfferSection.title'),"subtitle":_vm.$t('sections.sell-us-process.noOfferSection.subtitle')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.noOfferSection.inputs.name.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.noOfferSection.inputs.name.placeholder'
          ),"value":_vm.name},on:{"input":function (e) { return _vm.handleInput('name', e); }}}),_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.noOfferSection.inputs.email.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.noOfferSection.inputs.email.placeholder'
          ),"value":_vm.email},on:{"input":function (e) { return _vm.handleInput('email', e); }}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }