var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessCard',{attrs:{"title":_vm.$t('sections.sell-us-process.representationInfoSection.title')}},[_c('template',{slot:"body"},[_c('div',{staticClass:"inputs"},[_c('ChoiceCardGroup',{attrs:{"name":"representation","choices":_vm.$t(
            'sections.sell-us-process.representationInfoSection.inputs.cards'
          ),"value":_vm.representationType},on:{"onChoice":function (e) { return _vm.handleInput('representationType', e); }}}),(
          _vm.representationType ===
            _vm.$t(
              'sections.sell-us-process.representationInfoSection.inputs.cards'
            )[1]
        )?_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
            'sections.sell-us-process.representationInfoSection.inputs.agencyName.value'
          ),"placeholder":_vm.$t(
            'sections.sell-us-process.representationInfoSection.inputs.agencyName.placeholder'
          ),"value":_vm.agencyName,"valid":this.validFields.agencyName,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('agencyName', e); }}}):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }