<template>
  <ProcessCard success>
    <template slot="body">
      <div class="no-offer-success">
        <span class="no-offer-success__title">
          {{ $t("sections.sell-us-process.noOfferEndSection.title") }}
        </span>
        <BaseButton
          large
          :label="$t('sections.sell-us-process.noOfferEndSection.button')"
          class="align-self-center"
        />
      </div>
    </template>
  </ProcessCard>
</template>
<script>
import { BaseButton, ProcessCard } from 'ff-components-lib';

export default {
  name: 'SellUsProcessNoOfferEndSection',
  components: {
    ProcessCard,
    BaseButton,
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-offer-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;
  margin-bottom: 77px;
  height: 100%;

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }
}
</style>
